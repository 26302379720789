import { Outlet, Link } from "react-router-dom";
import TopBar from '../../componentes/Nav/Nav'

const Layout = () => {
    return (
        <div className="App">
          <TopBar></TopBar>
          <Outlet/>
        </div>
      );
};

export default Layout;