import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,

} from 'reactstrap';

import './Nav.css'

function TopBar(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar {...args} className='nav' style={{fontFamily:'Comic Sans MS'}}>
        <NavbarBrand href="/">
            <div className='row'>
                <div className='col'>
                    <img src='/imagens/logo.png' alt='' style={{height:'3.5rem', marginLeft:'4rem'}} />
                </div>
                <div className='col d-flex flex-column justify-content-center align-items-center'>
                    <span style={{color: 'white', marginBottom: '0px'}}>
                        O ponto de encontro do setor farmaceutico
                    </span>
                </div>
            </div>
            
        </NavbarBrand>       
      </Navbar>
    </div>
  );
}

export default TopBar;