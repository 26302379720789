import { 
    Col, 
    Container, 
    Row,
    FormGroup,
    Input, 
    Label,
    Button
} from "reactstrap";

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Icon from '@mui/material/Icon';

function TesteEmail(){
    return(
        <div style={{fontSize:'1.15rem', marginLeft:'1rem', fontFamily:'arial'}}>
            <img src="https://drive.google.com/uc?export=view&id=1wBC_yrhrl_FcVn1xnByU8-CL_EHs1N2x" alt="Logotipo" width="200rem" style={{marginBottom: '1rem'}} />
            <br />
            <span>Olá, Leandro! </span>
                <br />
            <div style={{marginTop:'1rem'}}>  
                <span>Tentando fazer login no Portal da Drogaria?</span>        
                <br />
                <span>Se sim, clique no link abaixo para receBer sua senha temporária</span>    
            </div>
            <div style={{marginTop:'1rem', fontSize:'1rem'}}>  
                <a style={{ backgroundColor: '#32699A', color: 'white',borderRadius: '8px',border: 'none'}}
                href="http://localhost:3000/Password?key=4286A5336C564BEEEB08E48B99EEB848D832CAB0ED94BF27BD8B6CD4D32AAD66B2F9189FEE1132019FE7ED7A6486A275019440AFADCA2722FBF7BD2F2DC8CA40433F8ACEA57C3D8DFE73FDE7E6E9BCEB">
                    Clique aqui para gerar sua senha temporária
                </a>   
                {/* <button style={{backgroundColor: '#32699A', color: 'white',borderRadius: '8px',border: 'none'}} onClick={() => {window.location.href = "http://localhost:3000/Password?key=4286A5336C564BEEEB08E48B99EEB848D832CAB0ED94BF27BD8B6CD4D32AAD66B2F9189FEE1132019FE7ED7A6486A275019440AFADCA2722FBF7BD2F2DC8CA40433F8ACEA57C3D8DFE73FDE7E6E9BCEB"}}>
                    RECEBER NOVA SENHA
                </button> */}
            </div>
    
            
        </div>
    )
}

export default TesteEmail;