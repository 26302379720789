import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from './pages/main/main'
import SuccessPage from './pages/success/success'
import  PasswordPage  from './pages/password/password'
import  ErrorPage  from './pages/error/error'
import  IncorrectPage  from './pages/incorrect/incorrect'
import  TesteEmail  from './pages/teste/teste'
import Layout from './pages/layout/layout';


export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MainPage />} />
          <Route path="Success" element={<SuccessPage />} />
          <Route path="Password" element={<PasswordPage />} />
          <Route path="Error" element={<ErrorPage />} />
          <Route path="Incorrect" element={<IncorrectPage />} />
          <Route path="Teste" element={<TesteEmail />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

// const cors = require("cors");
// App.use(cors());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
