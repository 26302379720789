import { 
    Col, 
    Container, 
    Row,
    FormGroup,
    Input, 
    Label,
    Button,
    Badge,
    Spinner
} from "reactstrap";
import { useSearchParams,useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";


function PasswordPage(){

    const [queryParameters] = useSearchParams();
    const [carregando, setCarregando] = useState(true);
    const [tempPass, setTempPass] = useState("");
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;

    useEffect(() => {
        let key = queryParameters.get("key");
        console.log(key);

        GenerateNewPass(key);

        // fetch(fullUrl)
        //    .then((response) =>  response.json())
        //    .then((data) => {setEmails(data); setSelectedEmail(data[0].key); setCarregando(false);})

    }, []);

    const GenerateNewPass = async (key) =>{
        
        let fullUrl = `${apiUrl}/api/Reset/GenerateNewPassWord`;
        console.log(fullUrl);

        const response = await fetch(fullUrl, {
            method: 'POST',
            body: JSON.stringify({
                key: key
             }),
            headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'api-key': apiKey
            },
        });

        let data = await response.json();

        if(response.ok){ 

            console.log(data);
            if(data.data == "Expirado")
            {
                
                navigate("/Incorrect");
            }
            else
            {
                setTempPass(data.data);
                setCarregando(false);
            }
        } 
        else{ 
            navigate("/Incorrect");
        }


    }

    return(
        <>
            <Container style={{marginTop: '6rem', textAlign: "center"}}  className={carregando?"d-none":""}>
                <Row style={{marginTop:'1rem'}}>
                    <Col>
                        <h1>Senha Temporária*</h1>
                    </Col>
                </Row>
                <Row style={{marginTop: '1rem'}}>
                    <Col>
                        <h2>
                            <Badge id="senha" className="text-dark" color="light">
                                {tempPass}
                            </Badge>
                        </h2>
                    </Col>
                </Row>
                <Row style={{marginTop:'2rem'}}>
                    <Col>
                        <Button size="lg" style={{backgroundColor: '#32699A'}} onClick={() => { window.location.href = "https://www.portaldadrogaria.com.br/11v1/" }}>
                            Voltar para o Portal da Drogaria
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span style={{color:'red'}}>*Essa senha expirará em 10 minutos</span>
                    </Col>
                </Row>
            </Container>
            <Container style={{height:'100%'}} className={(!carregando?"d-none":"")}>
                <Row style={{height:'88vh'}}>
                    <Col className="d-flex justify-content-center" style={{position:'relative', top:'45%'}}>
                        <Spinner>
                            Loading...
                        </Spinner>
                    </Col>

                </Row>

            </Container>
        </>
    )
}

export default PasswordPage;