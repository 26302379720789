import { 
    Col, 
    Container, 
    Row,
    FormGroup,
    Input, 
    Label,
    Button,
    Spinner
} from "reactstrap";
import { useSearchParams,useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
import { height } from "@mui/system";

function MainPage(){

    const [queryParameters] = useSearchParams();
    const [emails, setEmails] = useState([]);
    const [carregando, setCarregando] = useState(true);
    const [selectedEmail, setSelectedEmail] = useState([]);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;

    useEffect(() => {
        let key = queryParameters.get("key");
        let fullUrl = `${apiUrl}/api/Reset/emails/${key}`;

        if(key == null || key == "")
        {
            navigate("/Incorrect");
        }

        const Get = async () =>
        {
            console.log("Entrou no get");
            console.log(apiUrl);
            var response = await fetch(fullUrl,{
                headers: {
                  'api-key': apiKey,
                }});

            if(response.status == 204)
            {
                navigate("/Incorrect");
            }
            else if(response.status == 200)
            {
                var data = await response.json();
                setEmails(data);
                setSelectedEmail(data[0].key);
                setCarregando(false);
            }
            else if(Array.from(response.status.toString())[0] == '5')
            {
                navigate("/error");

            }
            else
            {
                navigate("/Incorrect");
            }
        }

        try
        {
            Get();
        }
        catch(exception)
        {
            navigate("/error");
        }

     }, []);

   

    const AoSelecionar = (event) =>{
        setSelectedEmail(event.target.value);
    }

    const OnEnviarClick = (event) =>{
        
        let fullUrl = `${apiUrl}/api/Reset/SendEmail`;  
        setCarregando(true);
        fetch(fullUrl, {
            method: 'POST',
            body: JSON.stringify({
                key: selectedEmail
             }),
            headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'api-key':apiKey
            },
            })
            .then((response) => {
                if(response.ok){ 
                    navigate("/Success");
                } 
                else{ 
                    navigate("/Error");
                }
            })
            .catch((err) => {
            console.log(err.message);
            });
    }

    return(
        <>
            <Container style={{marginTop: '6rem', textAlign: "start"}} className={carregando?"d-none":""}>
                <Row>
                    <Col xs="5" className="text-left"  style={{textAlign: "start", fontSize:"1.2rem"}}> 
                        <span>
                            Selecione o e-mail cadastrado no portal da drogaria para o envio da senha temporária:
                        </span>
                    </Col>
                </Row>
                <Row style={{marginTop:'2rem'}}>
                    <Col>
                        <FormGroup tag="fieldset">
                        {emails.map((item,index) => (
                            <FormGroup check style={{marginBottom:'1rem'}} key={"radion-" + index}>
                                <Input name="radio1" type="radio" value={item.key}   onChange={AoSelecionar} checked={index == 0}/>
                                <Label check>
                                    <strong>{item.email}</strong>
                                </Label>
                            </FormGroup>
                        ))}

                            {/* <FormGroup check style={{marginBottom:'1rem'}}>
                                <Input id="radio1" name="radio1" type="radio" value={"oleovalentim@gmail.com"}   onChange={AoSelecionar}/>
                                <Label check>
                                    <strong>oleovalentim@gmail.com</strong>
                                </Label>
                            </FormGroup>
                            <FormGroup check style={{marginBottom:'1rem'}}>
                                <Input id="radio2" name="radio1" type="radio" value={"leandro.valentim@ecs.com.br"}   onChange={AoSelecionar} />
                                <Label>
                                    <strong>leandro.valentim@ecs.com.br</strong>
                                </Label>
                            </FormGroup> */}
                        </FormGroup>
                    </Col>
                </Row>
                <Row  style={{marginTop:'4rem', textAlign: "start"}}>
                    <Col xs='1'>
                        <Button size="lg"  style={{backgroundColor: '#32699A'}} onClick={() => {OnEnviarClick()}}>
                            Enviar
                        </Button>
                    </Col>
                    <Col>
                        <Button size="lg" style={{backgroundColor: '#32699A'}} onClick={() => { window.location.href = "https://www.portaldadrogaria.com.br/11v1" }}>
                            Voltar para o Portal da Drogaria
                        </Button>
                    </Col>
                    {/* <Col> 
                        <div>
                            <p style={{fontSize:'0.7rem', marginBottom: '0px'}}>ou <a href="#">Voltar para Home </a></p>
                        </div>                 
                    </Col> */}
                </Row>
            </Container>
            <Container style={{height:'100%'}} className={(!carregando?"d-none":"")}>
                <Row style={{height:'88vh'}}>
                    <Col className="d-flex justify-content-center" style={{position:'relative', top:'45%'}}>
                        <Spinner>
                            Loading...
                        </Spinner>
                    </Col>

                </Row>

            </Container>
        </>
    )
}

export default MainPage;